import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

import { Task } from "util/typesAlt";
import { formatDatetimeString, getColorValue } from "util/util";
import { getTaskPoints } from "hooks/useTasks";

const TaskCard = ({
  task,
  disableRedact = false,
}: {
  task: Task;
  disableRedact?: boolean;
}) => {
  const shouldRedact = task.about?.redact && !disableRedact;

  const [showDetail, setShowDetail] = useState(false);

  const points = getTaskPoints(task);

  const formatMultiline = (text: string | undefined): JSX.Element => {
    return (
      <>
        {text !== undefined &&
          text.split("\n").map((line) => <div className="mb-2">{line}</div>)}
      </>
    );
  };

  return (
    <div className="flex flex-col rounded-lg overflow-hidden">
      <div
        className="flex items-center h-14 bg-gray-850"
        onClick={() => {
          if (!task.about?.rewardId) return setShowDetail(!showDetail);
        }}
      >
        <div
          className={`relative w-14 h-full flex items-center text-gray-950 bg-${getColorValue(
            task.about?.color
          )}`}
        >
          {task.promptResponse?.initiative ? (
            <>
              <div className="absolute left-0 right-0 text-center text-4xl text-gray-925">
                <FontAwesomeIcon icon={faStar} />
              </div>
              <div
                className={`absolute w-full text-center text-xl text-${getColorValue(
                  task.about?.color
                )}`}
              >
                {!task.about?.rewardId && !task.feedback?.points ? "?" : points}
              </div>
            </>
          ) : (
            <div className="ml-auto mr-auto text-xl text-gray-925">
              {!task.about?.rewardId && !task.feedback?.points ? "?" : points}
            </div>
          )}
        </div>
        <div className="p-3 flex-1 h-full flex items-center text-gray-200 break-all overflow-y-hidden">
          <div>{task.about?.title}</div>
        </div>
        {!task.about?.rewardId && (
          <div className="pr-3 text-gray-500">
            <FontAwesomeIcon icon={showDetail ? faChevronUp : faChevronDown} />
          </div>
        )}
      </div>
      {showDetail && (
        <div className="bg-gray-700 p-2 flex flex-col gap-1 whitespace-pre-line">
          {task.promptResponse?.initiative && (
            <div className="my-1 relative text-green-500">
              <div
                className={`absolute text-3xl -mt-2 -ml-1 text-${getColorValue(
                  task.about?.color
                )}`}
              >
                <FontAwesomeIcon icon={faStar} />
              </div>
              <span className="relative text-gray-700 ml-1 mr-2">+1</span>
              for Initiative!
            </div>
          )}
          <div>
            {task.feedback?.datetime
              ? formatDatetimeString(task.feedback.datetime)
              : task.createdDatetime
              ? `[ Draft created: ${formatDatetimeString(
                  task.createdDatetime
                )} ]`
              : ""}
          </div>
          {!shouldRedact ? (
            <>
              <div className="border-t mt-2 border-gray-500 flex rounded-s">
                <div className="bg-gray-500 rounded-br px-1">Context:</div>
              </div>
              <div className="mb-2">{task.promptResponse?.context}</div>
              <div className="border-t border-gray-500 flex rounded-s">
                <div className="bg-gray-500 rounded-br px-1">Task:</div>
              </div>
              <div className="mb-2">
                {formatMultiline(task.about?.description)}
              </div>
              <div className="border-t border-gray-500 flex rounded-s">
                <div className="bg-gray-500 rounded-br px-1">Andrea:</div>
              </div>
              <div>
                {task.response?.mediaDescription
                  ? formatMultiline(`[ ${task.response.mediaDescription} ]`)
                  : ""}
              </div>
              <div>{formatMultiline(task.response?.text)}</div>
              <div className="border-t border-gray-500 flex rounded-s">
                <div className="bg-gray-500 rounded-br px-1">Feedback:</div>
              </div>
              <div>{formatMultiline(task.feedback?.text)}</div>
              <ul className="list-disc">
                {task.feedback?.achievements &&
                  task.feedback.achievements
                    .split(";")
                    .map((achievement, i) => (
                      <li key={i} className="ml-4">
                        {achievement.trim()}
                      </li>
                    ))}
              </ul>
            </>
          ) : (
            <div>[ REDACTED ]</div>
          )}
        </div>
      )}
    </div>
  );
};

export default TaskCard;
